import React from 'react';
import classes from "./workRightStorageTable.module.css";
import {deleteSample, getSample} from "../../../../utils/API/api_sample";
import {modeContext} from "../../../Contexts/contexts";
import {useDrag} from "react-dnd";
import {Store} from "../../../../store/Store";
import {ExtendedSampleContext} from "../WorkRightStorage/workRightStorage";
import { address_server_short } from '../../../../config';
import outSVG from "../../../../svg/sm_out-01.svg";
import {toJS} from "mobx";

export const SampleItem = ({packId, line, column, storageId, sampleId, index, cell, mode, setViewMode}) => {
  const rules = mode.rules ?? {};
  const {setShowEmptyDetail} = React.useContext(ExtendedSampleContext);
  const { widget } = React.useContext(modeContext);
  const { deleteSampleInPackById, loadTree, setSelectedSample, loadSampleById, selectedSample } = React.useContext(Store);
 
  const [{isDragging}, drag] = useDrag(() => ({
    type: 'sample',
    item: {packId: packId, storageId: storageId, sampleId: sampleId, line: line, column: column, value: cell.value.volume},
    collect: monitor => ({isDragging: !!monitor.isDragging()}),
  }));

  const onDelete = async () => {
    const deleteItem = async () => {
      let res = await deleteSample(mode.token, sampleId);
      if (res.success) {
        deleteSampleInPackById(packId, sampleId);
        await loadTree(mode.token, false, '');
        //await updateSelectedTreeNodePacks(mode.token);
      } else {
        widget.setWidget({
          status: 'Error',
          text: 'Возникла ошибка при попытке удалить образец!',
          fun: () => {}
        });
      }
    }

    widget.setWidget({
      status: 'Sure',
      text: 'Вы действительно хотите удалить образец?',
      fun: deleteItem
    });
  }

  const onLoadSample = async () => {
    await loadSampleById(mode.token, sampleId)
  }

  const onEdit = async () => {
    await onLoadSample();
    mode.setVisibleAdd(true);
    mode.setVisibleView(false);
    setShowEmptyDetail(false);
  }

  const onView = async (e) => {
    if (e.ctrlKey) {
      if (!rules?.sample?.move) return;

      if (elementForCopyMode()) {
        deleteElementForCopyMode();
      } else {
        await addElementForCopyMode();
      }
     } else {
      if (!rules?.sample?.get_info) return;
      await  onLoadSample();
      mode.setVisibleAdd(false);
      mode.setVisibleView(true);
    }
  }

  const elementForCopyMode = () => {
    let element = mode.selectedSamplesForCopy.find(i => i === sampleId);
    return !!element;
  }

  const elementForViewMode = () => {
    let element = mode.selectedSamplesForCopy.find(i => i === sampleId);
    return !!element;
  }

  const deleteElementForCopyMode = () => {
    let res = JSON.parse(JSON.stringify(mode.selectedSamplesForCopy));
    mode.setSelectedSamplesForCopy(res.filter(val => val != sampleId));
  }

  const addElementForCopyMode = async () => {
    let res = JSON.parse(JSON.stringify(mode.selectedSamplesForCopy));
    res.push(sampleId);
    mode.setSelectedSamplesForCopy(res);
  }

  return (
    <div key={index} className={classes.table_item_block_body}>
      <div className={classes.table_item_block}>
        <div className={classes.table_item}
             ref={rules?.sample?.move ? drag : null}
             style={{             
               opacity: isDragging || elementForCopyMode() ? 0.3 : 1,
               borderColor: cell?.value?.id == selectedSample?.id ? '#0d6efd': elementForCopyMode() ? '#01b454' : '#738087',
             }}
             onClick={onView}>
          <img src={address_server_short + cell.value.icons} className={classes.table_item_img + ' ' + (cell.status == 'extracted' ? classes.extracted: '')}/>
          {
              cell.status == 'extracted' && <img src={outSVG} className={classes.table_item_img + ' ' +  classes.image_extracted}></img>
          }

         
          {!cell.value.icons &&
            <div className={classes.table_item_add_block}>
              <div className={classes.table_item_add}></div>
            </div>
          }
        </div>
        { (
            cell.value.icons &&
            !isDragging &&
            !elementForCopyMode() &&
            cell.status != 'extracted' &&
            rules?.sample?.delete
          ) &&
          <div className={classes.table_item_delete} onClick={onDelete}>
          <div className={classes.table_item_delete_svg}></div>
        </div>}
        {
          (
            cell.value.icons &&
            !isDragging &&
            !elementForCopyMode() &&
            cell.status != 'extracted' &&
            rules?.sample?.upsert
          ) &&
          <div className={classes.table_item_edit} onClick={onEdit}>
            <div className={classes.table_item_edit_svg}></div>
          </div>
        }

      </div>
      <div className={`${classes.table_item} ${classes.table_item_ghost} `}></div>
    </div>
  );
};
