import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import SuperAdminPage from "./components/SuperAdminPage";
import ErrorPage from "./components/ErrorPage/ErrorPage";

String.prototype.toUnicode = function(){
    var result = "";
    for(var i = 0; i < this.length; i++){
        // Assumption: all characters are < 0xffff
        result += "\\u" + ("000" + this[i].charCodeAt(0).toString(16)).substr(-4);
    }
    return result;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/super-admin" element={<SuperAdminPage />} />
          <Route path="/recovery" element={<App />} />
      </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);
